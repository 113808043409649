import { RouteComponentProps, Router } from "@reach/router"
import React from "react"
import { DashboardRoute } from "../../../routes/roast/dashboard"
import { RobotsRoute } from "../../../routes/roast/robots"
import { ServerRoute } from "../../../routes/roast/server"
import { SitemapRoute } from "../../../routes/roast/sitemap"

const DashboardPage: React.FC<RouteComponentProps> = (props) => {
  return (
    <Router>
      <RobotsRoute path="/roast/dashboard/:id/robots/" />
      <ServerRoute path="/roast/dashboard/:id/server/" />
      <SitemapRoute path="/roast/dashboard/:id/sitemap/" />
      <DashboardRoute path="/roast/dashboard/" {...props} />
    </Router>
  )
}

export default DashboardPage
